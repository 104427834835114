import Image from 'next/image';
import { useMobileScreen } from '@lib/view/hooks/screens';
import React from 'react';
import { Button } from '@components/index';
import { IconEffectGrow } from '@components/icons';

interface Props {
    onScroll?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    name?: string;
    desc?: string;
    title?: string;
    styleTitle?: string;
}

const SubcriberSection: React.FC<Props> = ({
    onScroll,
    desc = '',
    title = '',
    styleTitle = 'w-full ml-0',
}) => {
    const isMobile = useMobileScreen();
    return (
        <div
            className="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-4"
            data-aos="fade-up"
        >
            <div className="order-last md:order-first self-center">
                <div className="flex flex-row justify-center md:justify-start w-full" />
                <div className="flex flex-col items-start">
                    <div
                        className={`flex mb-5 md:justify-start justify-center w-full ${styleTitle}`}
                    >
                        <h3
                            className="text-center md:text-left font-bold flex items-center text-violet-blackcurrant font-Circular-Std flex-row text-2xl xl:text-44px"
                            style={{
                                whiteSpace: isMobile ? 'nowrap' : 'pre-wrap',
                            }}
                        >
                            {title || `Grow Tiktok`}
                            <span
                                className={`ml-2 font-SourceSerifPro flex items-center img-folow-twitter relative font-bold leading-30px text-primary`}
                            >
                                Content Creation
                                <span
                                    className="absolute left-0"
                                    style={{ bottom: isMobile ? 0 : -10 }}
                                >
                                    <IconEffectGrow className="w-full" fill='#FCB045' />
                                </span>
                            </span>
                        </h3>
                    </div>
                    <p className="text-center md:text-left font-normal font-Inter md:max-w-lg pr-0 md:pr-6 text-base text-violet-smoky md:mt-0 -mt-1 md:mx-0 -mx-1 leading-34px">
                        {desc ||
                            'Simply let us know how many subscribers you need, and we will promote your channel within our community network to grow your channel to new heights.'}
                    </p>
                    <div className="flex flex-row justify-center md:justify-start w-full">
                        <Button onScroll={onScroll} />
                    </div>
                </div>
            </div>
            <div className="flex justify-center home-how-it-work-1">
                <Image
                    alt=""
                    src={`/images/home/how-it-works-01.png`}
                    width={597}
                    height={534}
                    objectFit="contain"
                />
            </div>
        </div>
    );
};

export default SubcriberSection;
