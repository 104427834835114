import { useCallback, useEffect } from 'react';
import FAQ_DATA from '@lib/constants/FAQ_DATA';
import { useMobileScreen } from '@lib/view/hooks/screens';
import AOS from 'aos';
import React from 'react';
import { Element, scroller } from 'react-scroll';
import FeedbackSection from '@home/feedback';
import FrequentlyAskQuestion from '@home/frequently-ask-quesiton';
import GrowthSection from '@home/growth';
import MoreDetailItem from '@home/more-details-item';
import SubcriberSection from '@home/subscriber';
import TrackOrderSection from '@home/track-order';
import PolicyAndReview from '@home/policy-and-review';
import Layout from '@lib/view/components/layout';
import { NextPage } from 'next';
import { useRouter } from 'next/router';

const HomePage: NextPage = () => {
    useEffect(() => {
        AOS.init({
            duration: 1200,
        });
    }, []);
    const { push } = useRouter();
    const onScroll = useCallback(() => {
        // event.preventDefault();
        scroller.scrollTo('input-element', {
            offset: 0,
            duration: 1000,
            delay: 0,
            smooth: 'easeInOutQuart',
        });
    }, []);

    const handleClickButton = () => {
        push('/create-order');
    };

    const mobile = useMobileScreen();
    return (
        <Layout footer titleDefault="Recordpals">
            {/* -----  Introduction ----- */}
            <section className="md:pb-0 relative bg-no-repeat bg-cover z-2">
                <div className="hidden xsMax:flex absolute top-0 right-0 left-0 bottom-0 bg-home-banner justify-center">
                    <div className="bg-home-recordpals-rp-linepals-header-gradient bg-no-repeat bg-right-bottom md:mx-35px container" />
                </div>
                <div className="flex xsMax:hidden absolute top-0 right-0 left-0 bottom-0 bg-home-banner" />
                <div className="flex w-full items-center text-white min-h-545px md:min-h-750px md:py-25 pt-25 pb-15">
                    <div className="flex items-center w-full mx-5 md:container md:mx-auto">
                        <div
                            className="text-center md:text-left sm:px-4 w-full max-w-150"
                            data-aos="fade-up"
                        >
                            <h1 className="font-Circular-Std font-bold leading-65px md:leading-90px flex md:justify-start justify-center flex-col">
                                <span className="md:text-70px text-45px text-primary">
                                    Automate your
                                </span>
                                <span className="md:text-80px text-4xl text-black md:mt-3">
                                    Social Content
                                </span>
                            </h1>

                            <Element name="input-element" />
                            <button
                                className=" bg-primary h-16 md:w-251px w-full rounded-lg mt-30px md:mt-35px mb-9 font-Inter font-semibold text-base"
                                onClick={handleClickButton}
                            >
                                Get started
                            </button>
                            <PolicyAndReview
                                spacing="md:pt-4 pt-2 mb-10"
                                reviewerSpacing="md:mb-0"
                                fontSizeTitle="text-13px md:opacity-100 opacity-70 md:text-base whitespace-nowrap md:leading-5 leading-4 font-Inter font-normal md:font-medium text-grey-mid-gray"
                                classBoxTitle="flex"
                                spacingChecked="mr-6px"
                            />
                        </div>
                    </div>
                </div>
            </section>
            {/* ----- How ViewPals works ----- */}
            <section className="md:container px-5 md:px-35px pt-8 md:pt-110px mx-auto">
                <SubcriberSection
                    onScroll={onScroll}
                    title="Effortless"
                    desc="Make your content creation process seamless with our groundbreaking order mechanism. Demand and acquire bespoke articles and content that align flawlessly with your requirements. Discover the convenience of procuring superior-quality content through our intuitive platform."
                />
                <GrowthSection
                    desc="Rely on our adept content squad for a wide range of writing tasks, from succinct Instagram captions to detailed blog posts and extensive SEO-friendly articles. We produce outstanding content molded to your guidelines, guaranteeing your needs are fulfilled proficiently."
                    onScroll={onScroll}
                    title="Bite-Sized"
                />
            </section>
            {/* ----- Trusted ----- */}
            <section className="h-420px md:h-500px relative mt-6 md:mt-150px  bg-no-repeat bg-cover">
                <FeedbackSection
                    desc="Recordpals transformed my copywriting strategy and propelled my business expansion. Their dependability is unparalleled."
                    name="-tiktok-rp"
                    userName="- Tim Krokowski"
                />
            </section>

            {/* ------view and subscriber----- */}
            <section className="md:container px-5 mx-auto flex flex-col md:flex-row justify-between space-y-16 space-x-0 md:space-y-0 md:space-x-20 pt-73px md:pt-124px">
                <MoreDetailItem
                    imageName="tiktok-rp-01"
                    title="Blog Entries"
                    desc="Boost conversion rates and engage your audience with our professional blog writing services. Our seasoned team will shape persuasive content that eloquently conveys your vision, yielding tangible outcomes for your enterprise."
                />
                <MoreDetailItem
                    imageName="tiktok-rp-02"
                    title="SEO Blog Entries"
                    desc='Elevate your search engine visibility and attract visitors to your website with our expert SEO blog writing services. Our specialists create top-tier, engaging narratives, optimized for search, to bring forth measurable success.'
                />
                <MoreDetailItem
                    imageName="tiktok-rp-03"
                    title="Instagram Posts Content"
                    desc="Differentiate yourself and enhance user interaction with our expert caption crafting services. Our squad shapes enthralling, brand-aligned descriptions that will revolutionize your Instagram persona. Begin your Instagram elevation journey now!"
                />
            </section>
            {/* -------View and subscriber end------- */}

            {/* ----------- */}

            <TrackOrderSection
                onScroll={onScroll}
                name="-tiktok-rp"
                title="Veteran"
                desc="Confide in our ensemble of experienced professional wordsmiths. Accumulating years of industry practice, we excel in producing captivating narratives that strike a chord with your intended audience and stimulate conversions. Our proficiency ensures extraordinary outcomes for your enterprise."
            />

            {/* --------------- */}

            {/* Frequently Ask Quesiton */}
            <section className="bg-white" id="faq">
                <Element name="faq" />
                <div
                    className="md:container px-5 md:px-0 pt-9 md:pt-48 pb-2 mx-auto md:max-w-750px"
                    data-aos="fade-up"
                >
                    <h2 className="text-center font-bold md:font-medium md:text-28px text-22px font-Circular-Std text-violet-blackcurrant">
                        Frequently Asked Questions
                    </h2>

                    <div className="mt-50px md:mt-20">
                        <div className="mb-73px md:mb-32">
                            <FrequentlyAskQuestion data={FAQ_DATA.homepage} />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default HomePage;
