import Image from 'next/image';
import { useMobileScreen } from '@lib/view/hooks/screens';
import { Button } from '@components/index';
import { IconEffectGrow } from '@lib/view/components/icons';

interface Props {
    onScroll: (event: React.MouseEvent<HTMLButtonElement>) => void;
    name?: string;
    desc?: string;
    title?: string;
    classSpacingBox?: string;
    classSpacingTitle?: string;
}

const GrowthSection: React.FC<Props> = ({
    onScroll,
    desc = '',
    title = '',
    classSpacingBox = 'mt-25',
    classSpacingTitle = 'md:pt-8',
}) => {
    const isMobile = useMobileScreen();
    return (
        <div
            className={`grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-4 md:mt-35 lg:mt-40 ${classSpacingBox}`}
            data-aos="fade-up"
        >
            <div className="order-last self-center">
                <div className="flex flex-col items-start md:pl-16">
                    <div className="flex items-center mb-4 w-full md:justify-start justify-center text-violet-blackcurrant">
                        <h3
                            className={`text-center flex items-center md:text-left font-bold md:font-bold text-2xl xl:text-44px font-Circular-Std ${classSpacingTitle}`}
                        >
                            {title || 'Channel growth for'}
                            <span
                                className={`ml-2 font-SourceSerifPro flex items-center img-folow-twitter relative font-bold leading-30px text-primary`}
                            >
                                Content
                                <span
                                    className="absolute left-0"
                                    style={{ bottom: isMobile ? 0 : -10 }}
                                >
                                    <IconEffectGrow className="w-full" fill='#FCB045' />
                                </span>
                            </span>
                        </h3>
                    </div>
                    <p className="md:px-1 text-center md:text-left max-w-lg pr-0 md:pr-6 text-base font-normal text-violet-smoky font-Inter leading-34px mx-auto md:mx-0">
                        {desc ||
                            'It doesn’t matter whether you are a musician, a business or simply run a small cooking channel. Our methods are universal and will work for any channel.  Click below to try it out.'}
                    </p>
                    <div className="w-full flex flex-row justify-center md:justify-start">
                        <Button onScroll={onScroll} />
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-center home-how-it-work-1 px-md-0">
                <Image
                    alt=""
                    src="/images/home/how-it-works-02.png"
                    objectFit="contain"
                    width={597}
                    height={544}
                />
            </div>
        </div>
    );
};

export default GrowthSection;
